import axios from "axios";

export function getHeaders(additional) {
  const dashUserToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const timezoneOffset = new Date().getTimezoneOffset();

  let headers = {
    ...additional,
    "X-Api-Timezone-Offset": timezoneOffset,
  };

  if (dashUserToken) {
    headers["Authorization"] = `Token ${dashUserToken}`;
  }
  return headers;
}

export function createUUID() {
  // used to generate a random UUID (for key props)
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : r && 0x3 | 0x8;
    return v.toString(16);
  });
}

export const setPageTitle = (title) => {
  const siteName = process.env.REACT_APP_SITE_TITLE;
  if (title) {
    document.title = siteName + " | " + title;
  } else {
    document.title = siteName;
  }
  return true;
};

export const getUserLocationDetails = async () => {
  const API_KEY = "d011aa6de6644cd093dbd2111eb59286";
  const url = `https://api.geoapify.com/v1/ipinfo?apiKey=${API_KEY}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log("Error occured while getting user location info");
    return {};
  }
};

export const sectionChartInfoObject = (chartLabel, data) => {
  let chartInfoObject = {
    chart_label: chartLabel ? chartLabel : "",
    data: {},
  };

  data?.map((chartInfo) => {
    const key = chartInfo?.chart_label;
    if (!!key) {
      chartInfoObject.data[key] = {};
      Object.keys(chartInfo?.data || {})?.map((data) => {
        chartInfoObject.data[key][data] = chartInfo?.data[data];
        return null;
      });
    }
    return null;
  });

  return chartInfoObject;
};

export function roundUpNearest(num) {
  let len = num.toString().length - 1;
  len = len === 0 ? 1 : len;
  let tens = Math.pow(10, len);

  return Math.ceil(num / tens) * tens;
}

export const createGradient = (svg, i) => {
  const linearGradientDef = svg
    .append("defs")
    .append("linearGradient")
    .attr("id", `gradient${i + 1}`)
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "100%")
    .attr("y2", "0%");

  linearGradientDef
    .append("stop")
    .attr("offset", "0%")
    .style("stop-opacity", "0");

  linearGradientDef
    .append("stop")
    .attr("offset", "50%")
    .style("stop-opacity", "0.1");

  linearGradientDef
    .append("stop")
    .attr("offset", "70%")
    .style("stop-opacity", "0.2");

  linearGradientDef
    .append("stop")
    .attr("offset", "80%")
    .style("stop-opacity", "0.3");

  linearGradientDef
    .append("stop")
    .attr("offset", "100%")
    .style("stop-opacity", "0.3");

  return { linearGradientDef };
};

export const getBase64Promise = (file) =>
  new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve({
        name: file?.name,
        data: reader?.result,
        size: file?.size,
      });
    };
    reader.onerror = function (error) {
      reject("Error: ", error);
    };
  });

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export const createRandomBytes = () =>
  new Promise((resolve, reject) => {
    crypto.randomBytes(30, (err, buff) => {
      if (err) reject(err);

      const token = buff.toString("hex");
      resolve(token);
    });
  });

export function swapKeysAndValues(obj) {
  const swapped = Object.entries(obj).map(([key, value]) => [value, key]);
  return Object.fromEntries(swapped);
}

export const downloadFile = (content, fileName) => {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function isObject(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function extractNumbersFromEnd(str) {
  // Use regular expression to match digits (\d+) at the end of the string ($)
  const numbers = str.match(/\d+$/);
  // If numbers are found, return the matched string, otherwise return null
  return numbers ? parseInt(numbers[0]) : null;
}
