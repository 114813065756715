import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ReactGA from "react-ga4";
import * as URLS from "./urls";
import withSuspense from "../utils/withSuspense";

import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";

// Auth
const RegisterPage = React.lazy(() => import("../pages/auth/Register"));
const LoginPage = React.lazy(() => import("../pages/auth/Login"));
const ResetPassword = React.lazy(() => import("../pages/auth/Reset"));

// Dashboard
const Home = React.lazy(() => import("../pages/Home/Home"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const Reports = React.lazy(() => import("../pages/Reports/Reports"));
const TemplatePage = React.lazy(() =>
  import("../pages/TemplatePage/TemplatePage")
);
const CompanyTemplatePage = React.lazy(() =>
  import("../pages/TemplatePage/CompanyTemplatePage")
);

// Accounting
const AccountingPage = React.lazy(() => import("../pages/Accounting"));
const PoDetails = React.lazy(() => import("../pages/Accounting/PoDetails"));
const GrnDetails = React.lazy(() => import("../pages/Accounting/GrnDetails"));

// Deals & Discounts
const DealsDiscounts = React.lazy(() =>
  import("../pages/DealsDiscounts/DealsDiscounts")
);

// Products
const Products = React.lazy(() => import("../pages/Products"));

// Inventory
const Inventory = React.lazy(() => import("../pages/Inventory"));

// Performance
const Performance = React.lazy(() => import("../pages/Performance"));

// Subscription
const Subscription = React.lazy(() =>
  import("../pages/Subscription/Subscription")
);
const PaymentStatus = React.lazy(() =>
  import("../pages/Subscription/PaymentStatus/PaymentStatus")
);

// Settings
const SettingsPage = React.lazy(() => import("../pages/Settings/Settings"));

// Vendor Registration
const VendorRegistration = React.lazy(() =>
  import("../pages/VendorRegistration/VendorRegistration")
);
const VendorRegistrationStatus = React.lazy(() =>
  import("../pages/VendorRegistration/VendorRegistrationStatus")
);

// Helpdesk
const Faqs = React.lazy(() => import("../pages/Helpdesk/Faqs"));
const ProductRoadmap = React.lazy(() =>
  import("../pages/Helpdesk/ProductRoadmap")
);
const GetInTouch = React.lazy(() => import("../pages/Helpdesk/GetInTouch"));
const UserGuides = React.lazy(() => import("../pages/Helpdesk/UserGuides"));

// Others
const NotFound = React.lazy(() => import("../pages/Others/NotFound"));
const AccessDenied = React.lazy(() => import("../pages/Others/AccessDenied"));
const ProcurementPolicy = React.lazy(() =>
  import("../pages/procurementPolicy/ProcurementPolicy")
);

// Google Analytics
const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const Routes = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <Switch>
      {/* Auth */}
      <GuestRoute
        exact
        path={URLS.REGISTER}
        component={withSuspense(RegisterPage)}
      />
      <GuestRoute
        exact
        path={URLS.LOGIN_PAGE}
        component={withSuspense(LoginPage)}
      />
      <Route
        exact
        path={URLS.PASSWORD_RESET_PAGE}
        component={withSuspense(ResetPassword)}
      />

      {/* Dashboard */}
      <PrivateRoute
        path={URLS.DASHBOARD}
        exact
        component={withSuspense(Dashboard)}
      />
      <PrivateRoute
        path={URLS.REPORTS}
        exact
        component={withSuspense(Reports)}
      />
      <PrivateRoute
        path={URLS.TEMPLATE_PAGE}
        exact
        component={withSuspense(TemplatePage)}
      />
      <PrivateRoute
        path={URLS.TEMPLATE_COMPANY_PAGE}
        exact
        component={withSuspense(CompanyTemplatePage)}
      />
      {/* Accounting */}
      <PrivateRoute
        exact
        path={URLS.ACCOUNTING}
        component={withSuspense(AccountingPage)}
      />
      <PrivateRoute
        exact
        path={URLS.PO_DETAILS}
        component={withSuspense(PoDetails)}
      />
      <PrivateRoute
        exact
        path={URLS.GRN_DETAILS}
        component={withSuspense(GrnDetails)}
      />

      {/* Deals & Discounts */}
      <PrivateRoute
        exact
        path={URLS.DEALS_DISCOUNT}
        component={withSuspense(DealsDiscounts)}
      />

      {/* Products */}
      <PrivateRoute
        exact
        path={URLS.PRODUCTS}
        component={withSuspense(Products)}
      />

      {/* Inventory */}
      <PrivateRoute
        exact
        path={URLS.INVENTORY}
        component={withSuspense(Inventory)}
      />

      {/* Performance */}
      <PrivateRoute
        exact
        path={URLS.PERFORMANCE}
        component={withSuspense(Performance)}
      />

      {/* Subscription */}
      <PrivateRoute
        path={URLS.SUBSCRIPTION}
        exact
        component={withSuspense(Subscription)}
      />
      <PrivateRoute
        path={URLS.SUBSCRIPTION_PAYMENT_STATUS}
        exact
        component={withSuspense(PaymentStatus)}
      />

      {/* Settings */}
      <PrivateRoute
        exact
        path={URLS.SETTINGS}
        component={withSuspense(SettingsPage)}
      />

      {/* Vendor Registration */}
      <PrivateRoute
        exact
        path={URLS.VENDOR_REGISTRATION}
        component={withSuspense(VendorRegistration)}
      />
      <PrivateRoute
        exact
        path={URLS.VENDOR_REGISTRATION_STATUS}
        component={withSuspense(VendorRegistrationStatus)}
      />

      {/* Helpdesk */}
      <PrivateRoute
        exact
        path={URLS.HELPDESK_USER_GUIDE}
        component={withSuspense(UserGuides)}
      />
      <PrivateRoute
        exact
        path={URLS.HELPDESK_FAQS}
        component={withSuspense(Faqs)}
      />
      <PrivateRoute
        exact
        path={URLS.HELPDESK_GET_IN_TOUCH}
        component={withSuspense(GetInTouch)}
      />
      <PrivateRoute
        exact
        path={URLS.HELPDESK_TICKET_DETAILS}
        component={withSuspense(GetInTouch)}
      />
      <PrivateRoute
        exact
        path={URLS.HELPDESK_PRODUCT_ROADMAP}
        component={withSuspense(ProductRoadmap)}
      />

      {/* Others */}
      <PrivateRoute
        exact
        path={URLS.PROCUREMENT_POLICY}
        component={withSuspense(ProcurementPolicy)}
      />
      <Route
        exact
        path={URLS.ACCESS_DENIED_PAGE}
        component={withSuspense(AccessDenied)}
      />
      <PrivateRoute path={"/"} exact component={withSuspense(Home)} />

      <Route component={withSuspense(NotFound)} />
    </Switch>
  );
};

export default Routes;
