import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
};

const pricingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.PRODUCT_PRICING_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        errors: {},
      };
    }
    case Types.PRODUCT_PRICING_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };
    }
    case Types.PRODUCT_PRICING_FAILURE: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload,
      };
    }
    default:
      return state;
  }
};

export default pricingReducer;
