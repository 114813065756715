import { getHeaders } from "../../../utils";
import axios from "../../../utils/axios";
import * as Types from "../actionTypes";
import queryString from "query-string";
import { failure, success } from "../../../utils/toast";
import { checkSubscription } from "../payment/paymentActions";
import { getAllIdentifiers } from "../chartData/identifiers";

export const register = (data, history) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });
  dispatch({ type: Types.USER_LOGIN_ERROR, payload: {} });

  axios
    .post("/auth/register/", data)
    .then((res) => {
      dispatch({ type: Types.REGISTRATION_SUCCESS });
      dispatch(
        login(
          {
            username: data.email,
            password: data.password,
          },
          history
        )
      );
    })
    .catch((error) => {
      dispatch({
        type: Types.REGISTRATION_FAILURE,
        payload: error.response ? error.response.data : {},
      });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    });
};

export const login = (loginCreds, history) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });
  dispatch({ type: Types.USER_LOGIN_ERROR, payload: {} });

  const url = queryString.parse(history.location.search).return_url || "/";

  axios
    .post("/auth/login/", loginCreds)
    .then((res) => {
      localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, res.data.key);
      localStorage.setItem("dash_assiduus_showGIF", true);
      window.dispatchEvent(new Event("dash_assiduus_showGIF"));

      dispatch({ type: Types.USER_LOGGED_IN });
      history.push(url);
      dispatch(loadUserInfo());
    })
    .catch((error) => {
      dispatch({
        type: Types.USER_LOGIN_ERROR,
        payload: error.response ? error.response.data : {},
      });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    });
};

export const logout = (history) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });

  axios
    .post("/auth/logout/", {}, { headers: getHeaders() })
    .then((res) => {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);

      dispatch({ type: Types.USER_LOGGED_OUT });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    })
    .catch((error) => {
      dispatch({
        type: Types.USER_LOGOUT_ERROR,
        payload: error.response ? error.response.data : {},
      });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    });
};

export const loadUserInfo = () => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });

  axios
    .get("/auth/user/me/", { headers: getHeaders() })
    .then((res) => {
      if (res.data.should_logout) {
        dispatch(logout());
      }
      dispatch({ type: Types.USER_LOGGED_IN, payload: res.data });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch(isVendorRegistered());
      dispatch(checkSubscription());
      dispatch(getAllIdentifiers());
    })
    .catch((error) => {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
      dispatch({ type: Types.USER_LOGGED_OUT });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    });
};

export const forgotPassword = (email, done) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });
  dispatch({ type: Types.FORGOT_PASSWORD_FAIL, payload: false });
  axios
    .post("auth/password/reset/", {
      email,
    })
    .then((res) => {
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch({ type: Types.USER_FORGOT_PASSWORD_SUCCESS, payload: true });
      setTimeout(() => {
        dispatch({ type: Types.TOGGLE_FORGOT_PASSWORD, payload: false });
        dispatch({ type: Types.USER_FORGOT_PASSWORD_SUCCESS, payload: false });
      }, 5000);
      done("email is sent");
    })
    .catch((error) => {
      dispatch({ type: Types.FORGOT_PASSWORD_FAIL, payload: true });
      dispatch({ type: Types.AUTH_LOADING, payload: false });
    });
};

export const resetPassword =
  (token, uid, new_password1, new_password2) => (dispatch) => {
    dispatch({ type: Types.AUTH_LOADING, payload: true });
    dispatch({ type: Types.RESET_PASSWORD_ERROR, payload: {} });

    axios
      .post("/auth/password/reset/confirm/", {
        new_password1,
        new_password2,
        uid,
        token,
      })
      .then((res) => {
        dispatch({ type: Types.AUTH_LOADING, payload: false });
        dispatch({ type: Types.RESET_PASSWORD_STATUS, payload: true });
      })
      .catch((error) => {
        dispatch({ type: Types.AUTH_LOADING, payload: false });
        dispatch({
          type: Types.RESET_PASSWORD_ERROR,
          payload: error.response.data,
        });
      });
  };

export const changePassword = (passwordData) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });
  dispatch({ type: Types.CHANGE_PASSWORD_ERRORS, payload: {} });

  axios
    .post("/auth/password/change/", passwordData, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch({ type: Types.PASSWORD_CHANGED });
    })
    .catch((error) => {
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch({
        type: Types.CHANGE_PASSWORD_ERRORS,
        payload: error.response.data,
      });
    });
};

export const updateProfile = (profileData) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOADING, payload: true });
  dispatch({ type: Types.PROFILE_UPDATE_ERRORS, payload: {} });

  axios
    .post("/auth/user/me/", profileData, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch({ type: Types.PROFILE_UPDATED, payload: res.data.data });
      success("Profile updated successfully!");
    })
    .catch((error) => {
      dispatch({ type: Types.AUTH_LOADING, payload: false });
      dispatch({
        type: Types.PROFILE_UPDATE_ERRORS,
        payload: error.response.data,
      });
    });
};

export const vendorRegistration = (data) => (dispatch) => {
  dispatch({ type: Types.VENDOR_REGISTRATION_LOADING });

  axios
    .post(
      "/auth/user/vendor-registration/",
      { ...data },
      { headers: getHeaders() }
    )
    .then((res) => {
      dispatch({ type: Types.VENDOR_REGISTRATION_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Types.VENDOR_REGISTRATION_FAILURE,
        payload: error?.response ? error.response?.data : {},
        data,
      });
    });
};

export const isVendorRegistered = () => (dispatch) => {
  dispatch({ type: Types.VENDOR_REGISTRATION_LOADING });
  axios
    .get("/auth/user/vendor-registration/", { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.VENDOR_REGISTRATION_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Types.VENDOR_REGISTRATION_FAILURE,
        payload: error.response ? error.response.data : {},
      });
    });
};

export const setDefaultCountry = (data) => (dispatch) => {
  axios
    .post("/auth/user/me/default-country/", data, { headers: getHeaders() })
    .then((res) => {
      dispatch(loadUserInfo());
    })
    .catch((error) => {
      failure("Couldn't set default country!");
    });
};

export const acceptProcurementPolicy = () => (dispatch) => {
  axios
    .post(
      "/auth/user/me/procurement-policy-status/",
      { procurement_policy_status: "Accepted" },
      { headers: getHeaders() }
    )
    .then((res) => {
      dispatch(loadUserInfo());
    })
    .catch((error) => {
      failure();
    });
};
