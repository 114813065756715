import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { loadUserInfo } from "./store/actions/auth/authActions";
import "react-phone-number-input/style.css";
import "./styles/style.scss";
import queryString from "query-string";
import "react-confirm-alert/src/react-confirm-alert.css";

const userToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
if (userToken) {
  store.dispatch(loadUserInfo());
}

// set coupon code to localstorage for use in billing address page
const queryParams = queryString.parse(window.location.search);
const couponCode = queryParams?.couponCode || "";
const impactClickId = queryParams?.irclickid || "";
localStorage.setItem("couponCode", couponCode);
if (impactClickId) {
  localStorage.setItem("impactClickId", impactClickId);
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);
