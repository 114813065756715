import { combineReducers } from "redux";
import auth from "./authReducer";

import salesNugget from "./chartReducers/salesNuggetReducer";
import salesChart from "./chartReducers/salesChartReducer";

import adsNugget from "./chartReducers/adsNuggetReducer";
import adsChart from "./chartReducers/adsChartReducer";

import primaryAnalyticsChart from "./chartReducers/primaryAnalyticsChartReducer";
import detailedAnalyticsChart from "./chartReducers/detailedAnalyticsChartReducer";

import template from "./templateReducer";
import dateRange from "./dateRangeReducer";
import theme from "./themeReducer";
import sidebarToggle from "./sidebarToggleReducer";
import payment from "./paymentReducer";
import uploads from "./uploadsReducers";
import accounting from "./accountingReducer";
import productPricing from "./pricingReducer";
import performance from "./performanceReducer";
import reviews from "./reviewsReducer";
import dashboardChart from "./dashboardReducer";
import ticket from "./ticketReducer";
import identifiers from "./chartReducers/identifiersReducer";

const rootReducer = combineReducers({
  auth,
  
  salesNugget,
  salesChart,

  adsNugget,
  adsChart,

  primaryAnalyticsChart,
  detailedAnalyticsChart,

  template,
  dateRange,
  theme,
  sidebarToggle,
  payment,
  uploads,
  accounting,
  productPricing,
  performance,
  reviews,
  dashboardChart,
  ticket,
  identifiers,
});

export default rootReducer;
