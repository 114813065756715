import Routes from "./Routes/Routes";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CookiesProvider } from "react-cookie";
import { useSelector } from "react-redux";
import { VENDOR_REGISTRATION } from "./Routes/urls";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import FullScreenLoading from "./components/Loader/FullScreenLoading";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const App = () => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (
      !auth.vendorRegistration.loading &&
      (auth.vendorRegistration?.errors?.message ||
        (auth.vendorRegistration?.data?.status &&
          auth.vendorRegistration?.data?.status !== "Approved"))
    ) {
      history.push(VENDOR_REGISTRATION);
    }
  }, [auth, history]);

  if (auth.loading || auth.vendorRegistration.loading)
    return <FullScreenLoading />;

  return (
    <CookiesProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes />
    </CookiesProvider>
  );
};

export default App;
