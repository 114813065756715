// Auth
export const REGISTER = "/1ffc6c8633e78e99e1cab";
export const LOGIN_PAGE = "/login";
export const PASSWORD_RESET_PAGE = "/forget-password/reset/:uid/:token";

// Dashboard
export const DASHBOARD = "/dashboard";
export const REPORTS = "/reports";
export const TEMPLATE_PAGE =
  "/templates/:viewType(sales|advertisement|primary-analytics|detailed-analytics)/:brandId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)/:marketplace(amazon_seller|amazon_vendor|flipkart|shopify|all|noon|lazada|walmart)";
export const TEMPLATE_COMPANY_PAGE =
  "/templates/company/:viewType(sales|advertisement|primary-analytics|detailed-analytics)/:companyId/:country(India|United States|United Kingdom|United Arab Emirates|Kingdom of Saudi Arabia|Egypt|Singapore)/:marketplace(amazon_seller|amazon_vendor|flipkart|shopify|all|noon|lazada|walmart)";

// Accounting
export const ACCOUNTING =
  "/accounting/:type(accounting-reconciliation|procurement-invoicing|payables|receivables|ads-wallet)/";
export const ACCOUNTING_RECONCILIATION =
  "/accounting/accounting-reconciliation/";
export const ACCOUNTING_PROCUREMENT_INVOICING =
  "/accounting/procurement-invoicing/";
export const PO_DETAILS = "/accounting/po/details/";
export const GRN_DETAILS = "/accounting/grn/details/";
export const ACCOUNTING_PAYABLES = "/accounting/payables/";
export const ACCOUNTING_RECEIVABLES = "/accounting/receivables/";
export const ACCOUNTING_REFILL_ADS_WALLET = "/accounting/ads-wallet/";

// Deals & Discounts
export const DEALS_DISCOUNT = "/deals-discount/";

// Performance
export const PERFORMANCE = "/performance/:type(goals|benchmark|keywords)/";
export const PERFORMANCE_GOALS = "/performance/goals/";
export const PERFORMANCE_BENCHMARK = "/performance/benchmark/";
export const PERFORMANCE_KEYWORDS = "/performance/keywords/";

// Products
export const PRODUCTS =
  "/products/:type(new-listing|reviews|price-comparison|update-pricing)/";
export const PRODUCT_NEW_PRODUCT_LISTING = "/products/new-listing/";
export const PRODUCT_REVIEWS = "/products/reviews/";
export const PRODUCT_PRICE_COMPARISON = "/products/price-comparison/";
export const PRODUCT_UPDATE_PRICING = "/products/update-pricing/";

// Inventory
export const INVENTORY =
  "/inventory/:type(live-status|procurement-suggestion|upload-history)/";
export const INVENTORY_LIVE_STATUS = "/inventory/live-status/";
export const INVENTORY_PROCUREMENT_SUGGESTION =
  "/inventory/procurement-suggestion/";
export const INVENTORY_UPLOAD_HISTORY = "/inventory/upload-history/";

// Subscription
export const SUBSCRIPTION = "/subscription/";
export const SUBSCRIPTION_PAYMENT_STATUS = "/payment";

// Settings
export const SETTINGS =
  "/settings/:type(profile|password|manage-users|alerts)/";
export const SETTINGS_EDIT_PROFILE = "/settings/profile/";
export const SETTINGS_CHANGE_PASSWORD = "/settings/password/";
export const SETTINGS_MANAGE_USERS = "/settings/manage-users/";
export const SETTINGS_SETUP_ALERTS = "/settings/alerts/";

// Vendor Registration
export const VENDOR_REGISTRATION = "/vendor-registration/";
export const VENDOR_REGISTRATION_STATUS = "/vendor-registration/status";

// Helpdesk
export const HELPDESK = "/helpdesk/:type(user-guide)/";
export const HELPDESK_FAQS = "/helpdesk/faqs/";
export const HELPDESK_USER_GUIDE = "/helpdesk/user-guide/";
export const HELPDESK_PRODUCT_ROADMAP = "/helpdesk/product-roadmap/";
export const HELPDESK_GET_IN_TOUCH = "/helpdesk/get-in-touch/";
export const HELPDESK_TICKET_DETAILS =
  "/helpdesk/get-in-touch/ticket/:ticketId";

// Others
export const ACCESS_DENIED_PAGE = "/access-denied";
export const PROCUREMENT_POLICY = "/procurement-policy/";
